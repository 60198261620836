<app-loading [show]="loading"></app-loading>

<div class="menu-section">
  <img alt="Logo" class="logo" (click)="navigateToPage('')" src="assets/images/Logo.webp">

  <div class="routes margin-top20">
    <div *ngFor="let route of routes" class="route" (click)="navigateToPage(route.link)">
      {{route.name}}
    </div>
  </div>

  <img alt="Menu Icon" class="menu" mat-ripple src="assets/icons/menu.png" (click)="openSidebar()"/>
</div>

<div class="header">
  <img alt="Background" class="background" src="assets/images/Background.webp">

  <div class="message-section">
    <div>Ή ΤΟΥΣ ΤΕΛΕΙΩΝΟΥΜΕ</div>
    <div>Ή ΜΑΣ ΤΕΛΕΙΩΝΟΥΝ</div>
  </div>
</div>

<div class="main-container">
  <router-outlet></router-outlet>
</div>

<div class="footer">
  <div class="margin-bottom20">
    <div class="social-media">
      <div>Μπορείτε να μας βρείτε και στο Facebook</div>
      <img alt="Facebook icon" src="assets/icons/facebook.png" (click)="navigateToExternalPage()">
    </div>
  </div>
  <div>
    ©2023 Copyright Συμμαχία Ανατροπής All rights reserved.
  </div>
</div>
