import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Router} from '@angular/router';

@Component({
  styleUrls: ['./sidebar-dialog.component.scss'],
  templateUrl: './sidebar-dialog.component.html'
})
export class SidebarDialogComponent {
  public routes: any[];

  constructor(private matDialogRef: MatDialogRef<SidebarDialogComponent>,
              private router: Router,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.routes = this.data.routes;
  }

  public closeDialog(): void {
    this.matDialogRef.close();
  }

  public async navigateToPage(link: string): Promise<any> {
    await this.router.navigate([link]);
    this.matDialogRef.close();
  }
}
