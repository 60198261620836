<div mat-dialog-title>
  <span>Συμμαχία Ανατροπής</span>

  <span>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon>clear</mat-icon>
    </button>
  </span>
</div>

<mat-dialog-content>
  <div *ngFor="let route of routes" class="sidebar-route" (click)="navigateToPage(route.link)">
    {{route.name}}
  </div>
</mat-dialog-content>
