import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    children: [
      {
        loadChildren: () => import('./announcements-page/announcements-page.module')
          .then(m => m.AnnouncementsPageModule),
        path: 'announcements'
      },
      {
        loadChildren: () => import('./candidates-page/candidates-page.module').then(m => m.CandidatesPageModule),
        path: 'candidates'
      },
      {
        loadChildren: () => import('./home-page/home-page.module').then(m => m.HomePageModule),
        path: ''
      },
      {
        loadChildren: () => import('./participants-page/participants-page.module').then(m => m.ParticipantsPageModule),
        path: 'participants'
      },
      {
        loadChildren: () => import('./terms-page/terms-page.module').then(m => m.TermsPageModule),
        path: 'terms'
      },
      {
        loadChildren: () => import('./post-page/post-page.module').then(m => m.PostPageModule),
        path: ':slug'
      }
    ],
    path: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
