import {AppComponent} from './app.component';
import {AppErrorHandler} from './errors/app-error-handler';
import {AppRoutingModule} from './app-routing.module';
import {AuthorizationService} from './interceptors/authorization.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {ErrorDialogComponent} from './errors/error-dialog/error-dialog.component';
import {ErrorHandler, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LoadingComponent} from './shared/components/loading/loading.component';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {MaterialModule} from './shared/material.module';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';
import {SidebarDialogComponent} from './shared/dialogs/sidebar-dialog/sidebar-dialog.component';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    ErrorDialogComponent,
    LoadingComponent,
    SidebarDialogComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    HttpClientModule,
    MaterialModule,
    NgxGoogleAnalyticsModule.forRoot('G-23GZV7J676'),
    NgxGoogleAnalyticsRouterModule
  ],
  providers: [
    {
      provide: ErrorHandler, useClass: AppErrorHandler
    },
    {
      provide: LocationStrategy, useClass: PathLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: AuthorizationService, multi: true
    }
  ]
})
export class AppModule {
}
