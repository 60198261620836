import {Component, OnInit} from '@angular/core';
import {LoadingService} from './shared/services/loading.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {NavigationStart, Router} from '@angular/router';
import {SidebarDialogComponent} from './shared/dialogs/sidebar-dialog/sidebar-dialog.component';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  public loading: boolean;
  public routes: any[];

  constructor(private loadingService: LoadingService,
              private matDialog: MatDialog,
              private router: Router) {
    this.loading = false;

    this.routes = [
      {
        link: 'announcements',
        name: 'Ανακοινώσεις'
      },
      {
        link: 'participants',
        name: 'Συμμετέχοντες'
      },
      {
        link: 'candidates',
        name: 'Υποψήφιοι'
      },
      {
        link: 'terms',
        name: 'Θέσεις'
      }
    ];

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        window.scrollTo(0, 0);
      }
    });
  }

  public ngOnInit(): void {
    this.loadingService.events.subscribe((event) => {
      setTimeout(() => {
        this.loading = (event === 'show');
      });
    });
  }

  public navigateToExternalPage(): void {
    window.open('https://www.facebook.com/symmaxia2022/', '_blank');
  }

  public async navigateToPage(link: string): Promise<any> {
    await this.router.navigate([link]);
  }

  public openSidebar(): void {
    this.matDialog.open(SidebarDialogComponent, {
      data: {
        routes: this.routes
      },
      height: '100vh',
      position: {
        right: '0'
      }
    });
  }
}
